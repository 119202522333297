import React from "react"
import styled from "styled-components"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import BlogCard from "@components/blog/card"
import Breadcrumb from "@components/blog/breadcrumb"
import PropTypes from "prop-types"
import Grid from "@components/elements/grid"

const BlogTitle = styled.h1`
  font-size: 300%;
  margin: 10px 0 20px 0;
`

const BlogTopics = ({ data, pageContext }) => {
  return (
    <Layout canonical={pageContext.slug}>
      <Breadcrumb
        links={[
          {
            text: "Blog",
            url: "/blog",
          },
          {
            text: pageContext.topic,
          },
        ]}
      />
      <BlogTitle>{pageContext.topic} blogs</BlogTitle>
      <h3>A collection of our most recent articles on {pageContext.blog}</h3>
      <Grid gap="60px" margin="0 0 60px">
        {data.blogs.edges.map(({ node: blog }) => {
          const date = new Date(blog.date)
          const dateString = date.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
          return (
            <BlogCard
              key={blog.id}
              image={blog.image}
              title={blog.title}
              link={"/" + blog.slug}
              author={blog.authors[0]?.name}
              date={dateString}
            />
          )
        })}
      </Grid>
    </Layout>
  )
}

BlogTopics.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BlogTopics

export const pageQuery = graphql`
  query BlogTopicPageQuery($relatedBlogs: [String]!) {
    blogs: allBlog(filter: { id: { in: $relatedBlogs } }) {
      edges {
        node {
          title
          id
          status
          date
          slug
          image {
            id
            title
            cdn
            placeholder
          }
          authors {
            name
          }
        }
      }
    }
  }
`
